/* eslint-disable react/prop-types */
import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { DummyLayout as UiDummyLayout, Row, useScreenSize } from "@lookiero/sty-psp-ui";
import { Layout } from "../../../../layout/Layout";
import { style } from "./DummyLayout.style";

const DummyLayout: Layout = ({
  children,
  stepper,
  size = { M: "2/3", L: "1/3" },
  style: customStyle,
  scrollRef,
  ...props
}) => {
  const screenSize = useScreenSize();

  const contentContainerStyle = screenSize === "S" ? style.contentContainerSmall : style.contentContainerLarge;
  const contentContainerSizeForScreenSize = size[screenSize];

  return (
    <UiDummyLayout
      {...props}
      style={{ safeAreaView: [style.safeAreaView, customStyle?.safeAreaView], scrollView: customStyle?.scrollView }}
    >
      <Row style={style.row}>
        <ScrollView
          ref={scrollRef}
          bounces={false}
          overScrollMode="never"
          showsVerticalScrollIndicator={false}
          stickyHeaderIndices={[1]}
          style={StyleSheet.absoluteFill}
          contentContainerStyle={[
            style.contentContainer,
            contentContainerStyle,
            contentContainerSizeForScreenSize && style[contentContainerSizeForScreenSize],
          ]}
        >
          {stepper && <View style={style.stepper}>{stepper}</View>}

          <View style={[StyleSheet.absoluteFill, style.cardContainer]}>
            <View style={style.card} />
          </View>

          <View style={style.children}>{children}</View>
        </ScrollView>
      </Row>
    </UiDummyLayout>
  );
};

export { DummyLayout };
