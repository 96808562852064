import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, PageViewTrackingEvent, TrackingEventName } from "./tracking";

interface TrackPageViewFunction {
  (): void;
}

interface UseTrackQuestionModalPageViewFunctionArgs {
  readonly slug: string;
  readonly country: Country;
  readonly segment: Segment;
  readonly quizId: string | undefined;
}

interface UseTrackQuestionModalPageViewFunction {
  (agrs: UseTrackQuestionModalPageViewFunctionArgs): TrackPageViewFunction;
}

const useTrackQuestionModalPageView: UseTrackQuestionModalPageViewFunction = ({ slug, country, segment, quizId }) => {
  const emitUserEvent = useEmitUserEvent<PageViewTrackingEvent>();

  const trackPageView = useCallback(() => {
    if (!quizId) {
      return;
    }

    emitUserEvent({
      event: TrackingEventName.PAGEVIEW,
      eventCategory: TrackingEventCategory.NAVIGATION,
      section: `${PROJECT}_${slug}_modal`,
      store: country,
      segment,
      quizId,
    });
  }, [country, emitUserEvent, quizId, segment, slug]);

  return trackPageView;
};

export { useTrackQuestionModalPageView };
