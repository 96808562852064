import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { AnswersValidation } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, QuestionErrorTrackingEvent, TrackingEventName } from "./tracking";

interface TrackQuestionErrorFunctionArgs {
  readonly validation: AnswersValidation[] | undefined;
}

interface TrackQuestionErrorFunction {
  (args: TrackQuestionErrorFunctionArgs): void;
}

interface UseTrackQuestionErrorFunctionArgs {
  readonly slug: string | undefined;
  readonly country: Country;
  readonly segment: Segment;
  readonly quizId: string | undefined;
  readonly group: string;
}

interface UseTrackQuestionErrorFunction {
  (agrs: UseTrackQuestionErrorFunctionArgs): TrackQuestionErrorFunction;
}

const useTrackQuestionError: UseTrackQuestionErrorFunction = ({ slug, country, segment, quizId, group }) => {
  const emitUserEvent = useEmitUserEvent<QuestionErrorTrackingEvent>();

  const trackQuestionError: TrackQuestionErrorFunction = useCallback(
    ({ validation }) => {
      if (!(quizId && validation)) {
        return;
      }

      validation.forEach((v) => {
        const errorType = v.value ? "format" : v.mandatory && v.ctaTriggered ? "mandatory" : undefined;

        if (!errorType) {
          return;
        }

        emitUserEvent({
          event: TrackingEventName.QUESTION_ERROR,
          eventCategory: TrackingEventCategory.NAVIGATION,
          section: `${PROJECT}_${slug}`,
          store: country,
          segment,
          quizId,
          group,
          errorType,
        });
      });
    },
    [country, emitUserEvent, group, quizId, segment, slug],
  );

  return trackQuestionError;
};

export { useTrackQuestionError };
