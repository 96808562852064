import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingEventName, UploadPhotoErrorTrackingEvent } from "./tracking";

interface TrackUploadPhotoErrorFunction {
  (): void;
}

interface UseTrackUploadPhotoErrorFunctionArgs {
  readonly country: Country;
  readonly segment: Segment;
  readonly quizId: string | undefined;
}

interface UseTrackUploadPhotoErrorFunction {
  (agrs: UseTrackUploadPhotoErrorFunctionArgs): TrackUploadPhotoErrorFunction;
}

const useTrackUploadPhotoError: UseTrackUploadPhotoErrorFunction = ({ country, segment, quizId }) => {
  const emitUserEvent = useEmitUserEvent<UploadPhotoErrorTrackingEvent>();

  const trackUploadPhotoError: TrackUploadPhotoErrorFunction = useCallback(() => {
    if (!quizId) {
      return;
    }

    emitUserEvent({
      event: TrackingEventName.UPLOAD_PHOTO_ERROR,
      eventCategory: TrackingEventCategory.NAVIGATION,
      section: `${PROJECT}_photos`,
      store: country,
      segment,
      quizId,
    });
  }, [country, emitUserEvent, quizId, segment]);

  return trackUploadPhotoError;
};

export { useTrackUploadPhotoError };
