import { command, Command } from "@lookiero/messaging";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { Answers } from "../model/quiz";

const FILL_QUIZ = "fill_quiz";

interface FillQuizPayload {
  readonly aggregateId: string;
  readonly country: Country;
  readonly segment: Segment;
  readonly answers: Answers;
  readonly hostId: string;
  readonly experiment?: string;
}

interface FillQuiz extends Command<typeof FILL_QUIZ>, FillQuizPayload {}

interface FillQuizFunction {
  (payload: FillQuizPayload): FillQuiz;
}

const fillQuiz: FillQuizFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: FILL_QUIZ }),
  ...payload,
});

export { FILL_QUIZ, fillQuiz };
export type { FillQuiz };
