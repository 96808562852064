import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { Country } from "@lookiero/sty-psp-locale";
import { QuestionProjection } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { Segment } from "@lookiero/sty-psp-segment";

const VIEW_DEFINITION_BY_COUNTRY_AND_SEGMENT = "view_definition_by_country_and_segment";

interface ViewDefinitionByCountryAndSegmentPayload {
  readonly country: Country;
  readonly segment: Segment;
  readonly version?: string;
  readonly experiment?: string;
}

interface ViewDefinitionByCountryAndSegment
  extends Query<typeof VIEW_DEFINITION_BY_COUNTRY_AND_SEGMENT>,
    ViewDefinitionByCountryAndSegmentPayload {}

interface ViewDefinitionByCountryAndSegmentFunction {
  (payload: ViewDefinitionByCountryAndSegmentPayload): ViewDefinitionByCountryAndSegment;
}

const viewDefinitionByCountryAndSegment: ViewDefinitionByCountryAndSegmentFunction = (payload) => ({
  ...query({ name: VIEW_DEFINITION_BY_COUNTRY_AND_SEGMENT }),
  ...payload,
});

type ViewDefinitionByCountryAndSegmentResult = QuestionProjection[] | null;

interface DefinitionByCountryAndSegmentViewArgs extends CancelableQueryViewArgs {
  readonly country: Country;
  readonly segment: Segment;
  readonly version?: string;
  readonly experiment?: string;
}

interface DefinitionByCountryAndSegmentView {
  (args: DefinitionByCountryAndSegmentViewArgs): Promise<ViewDefinitionByCountryAndSegmentResult>;
}

interface ViewDefinitionByCountryAndSegmentHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: DefinitionByCountryAndSegmentView;
}

const viewDefinitionByCountryAndSegmentHandler: QueryHandlerFunction<
  ViewDefinitionByCountryAndSegment,
  ViewDefinitionByCountryAndSegmentResult,
  ViewDefinitionByCountryAndSegmentHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ country, segment, version, experiment }) =>
    view({ country, segment, version, experiment, signal });

export {
  VIEW_DEFINITION_BY_COUNTRY_AND_SEGMENT,
  viewDefinitionByCountryAndSegment,
  viewDefinitionByCountryAndSegmentHandler,
};
export type { DefinitionByCountryAndSegmentView, ViewDefinitionByCountryAndSegmentResult };
