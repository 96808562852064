import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingEventName, UploadPhotoChooseLibraryOptionCanceledTrackingEvent } from "./tracking";

interface TrackUploadPhotoChooseLibraryOptionCanceledFunction {
  (): void;
}

interface UseTrackUploadPhotoChooseLibraryOptionCanceledFunctionArgs {
  readonly country: Country;
  readonly segment: Segment;
  readonly quizId: string | undefined;
}

interface UseTrackUploadPhotoChooseLibraryOptionCanceledFunction {
  (
    agrs: UseTrackUploadPhotoChooseLibraryOptionCanceledFunctionArgs,
  ): TrackUploadPhotoChooseLibraryOptionCanceledFunction;
}

const useTrackUploadPhotoChooseLibraryOptionCanceled: UseTrackUploadPhotoChooseLibraryOptionCanceledFunction = ({
  country,
  segment,
  quizId,
}) => {
  const emitUserEvent = useEmitUserEvent<UploadPhotoChooseLibraryOptionCanceledTrackingEvent>();

  const trackUploadPhotoChooseLibraryOptionCanceled: TrackUploadPhotoChooseLibraryOptionCanceledFunction =
    useCallback(() => {
      if (!quizId) {
        return;
      }

      emitUserEvent({
        event: TrackingEventName.UPLOAD_PHOTO_CHOOSE_LIBRARY_OPTION_CANCELED,
        eventCategory: TrackingEventCategory.NAVIGATION,
        section: `${PROJECT}_photos`,
        store: country,
        segment,
        quizId,
      });
    }, [country, emitUserEvent, quizId, segment]);

  return trackUploadPhotoChooseLibraryOptionCanceled;
};

export { useTrackUploadPhotoChooseLibraryOptionCanceled };
