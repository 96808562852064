import { StyleSheet, ViewStyle } from "react-native";
import { ColumnSize, theme } from "@lookiero/sty-psp-ui";

const { colorBgBase, borderRadius5, colorBgSurface, space8 } = theme();

const stylesForContentContainerSize: Record<ColumnSize, ViewStyle> = {
  ["1/4"]: {
    maxWidth: `${100 * (1 / 4)}%`,
  },
  ["1/3"]: {
    maxWidth: `${100 * (1 / 3)}%`,
    left: `${100 * (1 / 3)}%`,
  },
  ["1/2"]: {
    maxWidth: `${100 * (1 / 2)}%`,
    left: `${100 * (1 / 4)}%`,
  },
  ["2/3"]: {
    maxWidth: `${100 * (2 / 3)}%`,
    left: `${100 * (1 / 6)}%`,
  },
  ["1"]: {
    maxWidth: "100%",
  },
};

const SPACE = space8;

const style = StyleSheet.create({
  card: {
    backgroundColor: colorBgBase,
    borderTopLeftRadius: borderRadius5,
    borderTopRightRadius: borderRadius5,
    height: SPACE,
  },
  cardContainer: {
    backgroundColor: colorBgSurface,
    height: SPACE,
    width: "100%",
  },
  children: {
    flex: 1,
    paddingTop: SPACE,
    zIndex: 20,
  },
  contentContainer: {
    backgroundColor: colorBgBase,
    paddingBottom: SPACE,
  },
  contentContainerLarge: {
    borderBottomLeftRadius: borderRadius5,
    borderBottomRightRadius: borderRadius5,
    marginBottom: SPACE,
  },
  contentContainerSmall: {
    flexGrow: 1,
  },
  row: {
    flex: 1,
    flexBasis: "auto",
  },
  safeAreaView: {
    backgroundColor: colorBgSurface,
  },
  stepper: {
    backgroundColor: colorBgSurface,
  },
  ...stylesForContentContainerSize,
});

export { style };
