import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { AnswersProjection } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { FillQuizTrackingEvent, PROJECT, TrackingEventName } from "./tracking";

interface TrackFillQuizFunctionArgs {
  readonly group: string;
  readonly question: string;
  readonly answers: AnswersProjection;
}
interface TrackFillQuizFunction {
  (args: TrackFillQuizFunctionArgs): void;
}

interface UseTrackFillQuizFunctionArgs {
  readonly slug: string;
  readonly country: Country;
  readonly segment: Segment;
  readonly quizId: string | undefined;
}

interface UseTrackFillQuizFunction {
  (agrs: UseTrackFillQuizFunctionArgs): TrackFillQuizFunction;
}

const useTrackFillQuiz: UseTrackFillQuizFunction = ({ slug, country, segment, quizId }) => {
  const emitUserEvent = useEmitUserEvent<FillQuizTrackingEvent>();

  const trackFillQuiz: TrackFillQuizFunction = useCallback(
    ({ group, question, answers }) => {
      if (!quizId) {
        return;
      }

      emitUserEvent({
        event: TrackingEventName.FILL_QUIZ,
        eventCategory: TrackingEventCategory.NAVIGATION,
        section: `${PROJECT}_${slug}`,
        store: country,
        segment,
        quizId,
        group,
        question,
        answers,
      });
    },
    [country, emitUserEvent, quizId, segment, slug],
  );

  return trackFillQuiz;
};

export { useTrackFillQuiz };
