import { query, Query, QueryHandlerFunction, QueryHandlerFunctionArgs } from "@lookiero/messaging";
import { Country } from "@lookiero/sty-psp-locale";
import { QuestionProjection } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { Segment } from "@lookiero/sty-psp-segment";
import { viewDefinitionByCountryAndSegment } from "./viewDefinitionByCountryAndSegment";

const VIEW_FIRST_QUESTION_FROM_DEFINITION_BY_COUNTRY_AND_SEGMENT =
  "view_first_question_from_definition_by_country_and_segment";

interface ViewFirstQuestionFromDefinitionByCountryAndSegmentPayload {
  readonly country: Country;
  readonly segment: Segment;
}

interface ViewFirstQuestionFromDefinitionByCountryAndSegment
  extends Query<typeof VIEW_FIRST_QUESTION_FROM_DEFINITION_BY_COUNTRY_AND_SEGMENT>,
    ViewFirstQuestionFromDefinitionByCountryAndSegmentPayload {}

interface ViewFirstQuestionFromDefinitionByCountryAndSegmentFunction {
  (
    payload: ViewFirstQuestionFromDefinitionByCountryAndSegmentPayload,
  ): ViewFirstQuestionFromDefinitionByCountryAndSegment;
}

const viewFirstQuestionFromDefinitionByCountryAndSegment: ViewFirstQuestionFromDefinitionByCountryAndSegmentFunction = (
  payload,
) => ({
  ...query({ name: VIEW_FIRST_QUESTION_FROM_DEFINITION_BY_COUNTRY_AND_SEGMENT }),
  ...payload,
});

type ViewFirstQuestionFromDefinitionByCountryAndSegmentResult = QuestionProjection | null;

interface ViewFirstQuestionFromDefinitionByCountryAndSegmentHandlerFunctionArgs extends QueryHandlerFunctionArgs {}

const viewFirstQuestionFromDefinitionByCountryAndSegmentHandler: QueryHandlerFunction<
  ViewFirstQuestionFromDefinitionByCountryAndSegment,
  ViewFirstQuestionFromDefinitionByCountryAndSegmentResult,
  ViewFirstQuestionFromDefinitionByCountryAndSegmentHandlerFunctionArgs
> =
  ({ queryBus }) =>
  async ({ country, segment }) => {
    const definition: QuestionProjection[] | null = await queryBus(
      viewDefinitionByCountryAndSegment({ country, segment, version: "usf" }),
    );

    if (!definition) {
      return null;
    }

    return definition[0] as QuestionProjection;
  };

export {
  VIEW_FIRST_QUESTION_FROM_DEFINITION_BY_COUNTRY_AND_SEGMENT,
  viewFirstQuestionFromDefinitionByCountryAndSegment,
  viewFirstQuestionFromDefinitionByCountryAndSegmentHandler,
};
export type { ViewFirstQuestionFromDefinitionByCountryAndSegmentResult };
