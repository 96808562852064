import { useCallback } from "react";
import invariant from "tiny-invariant";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { Country } from "@lookiero/sty-psp-locale";
import { Logger } from "@lookiero/sty-psp-logging";
import { Segment } from "@lookiero/sty-psp-segment";
import { fillQuiz } from "../../../../domain/quiz/command/fillQuiz";
import { Answers } from "../../../../domain/quiz/model/quiz";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";

interface FillQuizFunctionArgs {
  readonly answers: Answers;
}
interface FillQuizFunction {
  (args: FillQuizFunctionArgs): Promise<void> | undefined;
}

interface UseFillQuizArgs {
  readonly quizId: string | undefined;
  readonly country: Country;
  readonly segment: Segment;
  readonly hostId: string;
  readonly experiment?: string;
  readonly logger: Logger;
}

type UseFillQuizResult = [fill: FillQuizFunction, status: CommandStatus];

interface UseFillQuiz {
  (args: UseFillQuizArgs): UseFillQuizResult;
}

const useFillQuiz: UseFillQuiz = ({ quizId, country, segment, hostId, experiment, logger }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const fill: FillQuizFunction = useCallback(
    async ({ answers }) => {
      invariant(quizId, "quizId must be defined in order to fill quiz");

      try {
        await commandBus(fillQuiz({ aggregateId: quizId, country, segment, answers, hostId, experiment }));
      } catch (error) {
        logger.captureException(error as Error);
      }
    },
    [commandBus, country, experiment, hostId, logger, quizId, segment],
  );

  return [fill, status];
};

export { useFillQuiz };
