import { useCallback, useEffect } from "react";
import { v4 as uuid } from "uuid";
import { QueryStatus } from "@lookiero/messaging-react";
import { useLogger } from "@lookiero/sty-psp-logging";
import { useUpdateUiSetting, useViewUiSettingByKey } from "@lookiero/sty-psp-ui-settings";
import { QUIZ_UI_SETTING_ID } from "../../../../../../domain/quiz/model/quizId";
import { MESSAGING_CONTEXT_ID } from "../../../../../delivery/baseBootstrap";

type QuizId = string | undefined;

type UseQuizIdResult = [quizId: QuizId, createQuizId: () => void];

interface UseQuizIdFunction {
  (): UseQuizIdResult;
}
const useQuizId: UseQuizIdFunction = () => {
  const logger = useLogger();
  const [uiSetting, uiSettingStatus] = useViewUiSettingByKey({
    contextId: MESSAGING_CONTEXT_ID,
    key: QUIZ_UI_SETTING_ID,
  });
  const [updateUiSetting] = useUpdateUiSetting({ contextId: MESSAGING_CONTEXT_ID, logger });

  const createQuizId = useCallback(() => {
    updateUiSetting({
      aggregateId: QUIZ_UI_SETTING_ID,
      key: QUIZ_UI_SETTING_ID,
      value: uuid(),
    });
  }, [updateUiSetting]);

  useEffect(() => {
    if (uiSettingStatus === QueryStatus.SUCCESS && uiSetting === null) {
      createQuizId();
    }
  }, [createQuizId, uiSetting, uiSettingStatus]);

  return [uiSetting?.value as QuizId, createQuizId];
};

export { useQuizId };
