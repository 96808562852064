import { useLayoutEffect } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, PageViewTrackingEvent, TrackingEventName } from "./tracking";

interface UseTrackQuestionPageViewFunctionArgs {
  readonly slug: string | undefined;
  readonly country: Country;
  readonly segment: Segment;
  readonly quizId: string | undefined;
}

interface UseTrackQuestionPageViewFunction {
  (agrs: UseTrackQuestionPageViewFunctionArgs): void;
}

const useTrackQuestionPageView: UseTrackQuestionPageViewFunction = ({ slug, country, segment, quizId }) => {
  const emitUserEvent = useEmitUserEvent<PageViewTrackingEvent>();

  useLayoutEffect(() => {
    if (!(slug && quizId)) {
      return;
    }

    emitUserEvent({
      event: TrackingEventName.PAGEVIEW,
      eventCategory: TrackingEventCategory.NAVIGATION,
      section: `${PROJECT}_${slug}`,
      store: country,
      segment,
      quizId,
    });
  }, [country, emitUserEvent, quizId, segment, slug]);
};

export { useTrackQuestionPageView };
