import { isQuizFilled } from "../../../../domain/quiz/model/quizFilled";
import { HttpQuizzesSaveFunction } from "./httpQuizzes";

interface HttpQuizzesFillFunction extends HttpQuizzesSaveFunction {}

const httpQuizzesFill: HttpQuizzesFillFunction =
  ({ httpPost }) =>
  async ({ aggregateId, country, segment, answers, hostId, experiment, domainEvents }) => {
    const quizFilled = domainEvents.find(isQuizFilled);

    if (!quizFilled) {
      return;
    }

    await httpPost({
      endpoint: "/fill-quiz",
      body: {
        id: aggregateId,
        host: hostId,
        country,
        segment,
        answers,
        experiment,
      },
    });
  };

export { httpQuizzesFill };
