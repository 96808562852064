import { command, Command } from "@lookiero/messaging";
import { ImagePayload } from "../model/image";

const UPLOAD_IMAGE = "upload_image";

interface UploadImagePayload {
  readonly aggregateId: string;
  readonly quizId: string;
  readonly image: ImagePayload;
}

interface UploadImage extends Command<typeof UPLOAD_IMAGE>, UploadImagePayload {}

interface UploadImageFunction {
  (payload: UploadImagePayload): UploadImage;
}

const uploadImage: UploadImageFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: UPLOAD_IMAGE }),
  ...payload,
});

export type { UploadImage };
export { UPLOAD_IMAGE, uploadImage };
