import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const QUIZ_FILLED = "quiz_filled";

interface QuizFilledPayload {
  readonly aggregateId: string;
}

interface QuizFilled extends DomainEvent<typeof QUIZ_FILLED>, QuizFilledPayload {}

interface QuizFilledFunction {
  (payload: QuizFilledPayload): QuizFilled;
}

const quizFilled: QuizFilledFunction = ({ aggregateId }) => domainEvent({ aggregateId, name: QUIZ_FILLED });

const isQuizFilled = (event: DomainEvent<MessageName>): event is QuizFilled => event.name === QUIZ_FILLED;

export type { QuizFilled };
export { QUIZ_FILLED, isQuizFilled, quizFilled };
