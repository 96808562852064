// TODO properly export MessagingRoot from @lookiero/messaging-react
import React, { ComponentType, useCallback } from "react";
import { Platform } from "react-native";
import { useRoutes as reactRouterUseRoutes } from "react-router-native";
import { I18n } from "@lookiero/i18n-react";
import { MessagingRoot } from "@lookiero/messaging-react/bootstrap";
import { Country, Locale } from "@lookiero/sty-psp-locale";
import { SentryEnvironment, SentryLoggerFunctionArgs, sentryLogger, sentryLoggerHOC } from "@lookiero/sty-psp-logging";
import { Segment } from "@lookiero/sty-psp-segment";
import { KameleoonEnvironment } from "../ab-testing/kameleoonEnvironment";
import { Layout } from "../layout/Layout";
import { Routing } from "./routing/Routing";

interface RootFunctionArgs {
  readonly Messaging: MessagingRoot;
  readonly I18n: I18n;
  readonly development?: boolean;
  readonly sentry: () => SentryEnvironment;
  readonly kameleoon: () => KameleoonEnvironment;
}

interface RootFunction {
  (args: RootFunctionArgs): (args: SentryLoggerFunctionArgs) => ComponentType<RootProps>;
}

interface RootProps {
  readonly basePath: string;
  readonly locale?: Locale;
  readonly country: Country;
  readonly segment: Segment;
  readonly layout: Layout;
  readonly androidInAppBrowserVideoAd?: boolean;
  readonly apiUrl: () => string;
  readonly onBack?: () => void;
  readonly useRoutes?: typeof reactRouterUseRoutes;
}

const root: RootFunction = ({ Messaging, I18n, development, sentry, kameleoon: kameleoonConfig }) => {
  const logger = sentryLogger(sentry);
  const kameleoon = kameleoonConfig();

  const Root = ({
    basePath,
    locale = Locale.en_GB,
    country,
    segment,
    layout,
    androidInAppBrowserVideoAd = false,
    apiUrl,
    onBack,
    useRoutes = reactRouterUseRoutes,
  }: RootProps) => {
    const handleOnI18nError = useCallback((error: Error) => logger.captureException(error), []);

    return (
      <Messaging includeReactQueryDevTools={Platform.OS === "web"}>
        <Routing
          I18n={I18n}
          androidInAppBrowserVideoAd={androidInAppBrowserVideoAd}
          apiUrl={apiUrl}
          basePath={basePath}
          country={country}
          kameleoon={kameleoon}
          layout={layout}
          locale={locale}
          segment={segment}
          useRoutes={useRoutes}
          onBack={onBack}
          onI18nError={development ? undefined : handleOnI18nError}
        />
      </Messaging>
    );
  };

  const hoc = sentryLoggerHOC({ logger });

  /**
   * It's important to not call sentryLoggerHOC each time this function gets called.
   * That's why its called outside this method.
   */
  return ({ customerId, rootClient }) => hoc({ customerId, rootClient })(Root);
};

export { root };
export type { RootProps };
