import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingEventName, UploadPhotoTakePhotoOptionCanceledTrackingEvent } from "./tracking";

interface TrackUploadPhotoTakePhotoOptionCanceledFunction {
  (): void;
}

interface UseTrackUploadPhotoTakePhotoOptionCanceledFunctionArgs {
  readonly country: Country;
  readonly segment: Segment;
  readonly quizId: string | undefined;
}

interface UseTrackUploadPhotoTakePhotoOptionCanceledFunction {
  (agrs: UseTrackUploadPhotoTakePhotoOptionCanceledFunctionArgs): TrackUploadPhotoTakePhotoOptionCanceledFunction;
}

const useTrackUploadPhotoTakePhotoOptionCanceled: UseTrackUploadPhotoTakePhotoOptionCanceledFunction = ({
  country,
  segment,
  quizId,
}) => {
  const emitUserEvent = useEmitUserEvent<UploadPhotoTakePhotoOptionCanceledTrackingEvent>();

  const trackUploadPhotoTakePhotoOptionCanceled: TrackUploadPhotoTakePhotoOptionCanceledFunction = useCallback(() => {
    if (!quizId) {
      return;
    }

    emitUserEvent({
      event: TrackingEventName.UPLOAD_PHOTO_TAKE_PHOTO_OPTION_CANCELED,
      eventCategory: TrackingEventCategory.NAVIGATION,
      section: `${PROJECT}_photos`,
      store: country,
      segment,
      quizId,
    });
  }, [country, emitUserEvent, quizId, segment]);

  return trackUploadPhotoTakePhotoOptionCanceled;
};

export { useTrackUploadPhotoTakePhotoOptionCanceled };
