/* eslint-disable react/prop-types */
import React from "react";
import { Spinner } from "@lookiero/aurora";
import {
  HostScreenWithSummaryQuestionItem as HostScreenWithSummaryQuestionItemCommonUi,
  QuestionItem,
  QuestionType,
} from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { useViewQuizById } from "../../../../../../projection/quiz/react/useViewQuizById";
import { useQuizId } from "../../behaviours/useQuizId";
import { HostScreenCta } from "../hostScreenCta/HostScreenCta";

const HostScreenWithSummaryQuestionItem: QuestionItem<QuestionType.HOST_SCREEN_WITH_SUMMARY> = ({
  question,
  level,
  children,
  questionParentId,
}) => {
  const [quizId] = useQuizId();
  const [quiz] = useViewQuizById({ id: quizId });

  const dependenciesLoaded = quizId && quiz !== undefined;

  if (!dependenciesLoaded) {
    return <Spinner />;
  }

  return (
    <HostScreenWithSummaryQuestionItemCommonUi
      cta={<HostScreenCta question={question} quiz={quiz} quizId={quizId} />}
      level={level}
      question={question}
      questionParentId={questionParentId}
    >
      {children}
    </HostScreenWithSummaryQuestionItemCommonUi>
  );
};

export { HostScreenWithSummaryQuestionItem };
