import React, { FC, ReactNode, createContext, useContext, useEffect } from "react";
import invariant from "tiny-invariant";
import { UseQueryFunctionResult } from "@lookiero/messaging-react";
import { useAssignedVariationByExperimentId } from "@lookiero/sty-psp-ab-testing";
import { QuestionProjection } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { useViewDefinitionByCountryAndSegment } from "../../projection/definition/react/useViewDefinitionByCountryAndSegment";
import { AbTestTrackingEvent, TrackingEventName } from "../../tracking/tracking";
import { useQuizId } from "../components/organisms/question/behaviours/useQuizId";
import { useStaticInfo } from "./useStaticInfo";

interface DefinitionContextApi {
  readonly definition: UseQueryFunctionResult<QuestionProjection[]>;
  readonly experiment: string | undefined;
}

const DefinitionContext = createContext<DefinitionContextApi>(null as unknown as DefinitionContextApi);

interface DefinitionProviderProps {
  readonly children: ReactNode;
}

const DefinitionProvider: FC<DefinitionProviderProps> = ({ children }) => {
  const { country, segment, kameleoon } = useStaticInfo();
  const [quizId] = useQuizId();
  const emitUserEvent = useEmitUserEvent<AbTestTrackingEvent>();
  const { assignedVariation } = useAssignedVariationByExperimentId({ experimentId: kameleoon.experiment.id });
  const experiment =
    assignedVariation?.id !== undefined ? `${kameleoon.experiment.id}_${assignedVariation.id}` : undefined;
  const useViewDefinitionByCountryAndSegmentResult = useViewDefinitionByCountryAndSegment({
    country,
    segment,
    experiment,
    enabled: assignedVariation !== undefined,
  });

  useEffect(() => {
    if (!quizId || !assignedVariation) {
      return;
    }

    emitUserEvent({
      event: TrackingEventName.AB_TEST,
      eventCategory: TrackingEventCategory.NAVIGATION,
      store: country,
      section: "quiz",
      segment,
      quizId,
      experiment: kameleoon.experiment.id,
      variation: String(assignedVariation.id),
    });
  }, [assignedVariation, country, emitUserEvent, kameleoon.experiment.id, quizId, segment]);

  return (
    <DefinitionContext.Provider
      value={{
        definition: useViewDefinitionByCountryAndSegmentResult,
        experiment,
      }}
    >
      {children}
    </DefinitionContext.Provider>
  );
};

interface UseDefinitionFunction {
  (): DefinitionContextApi;
}

const useDefinition: UseDefinitionFunction = () => {
  const definition = useContext(DefinitionContext);

  invariant(
    definition,
    "Your are trying to use the useDefinition hook without wrapping your app with the <DefinitionProvider>.",
  );

  return definition;
};

export { DefinitionProvider, useDefinition };
