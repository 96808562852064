import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space2 } = theme();

const style = StyleSheet.create({
  container: {
    paddingVertical: space2,
  },
  stepContainer: {
    minHeight: 41,
  },
});

export { style };
