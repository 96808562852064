import invariant from "tiny-invariant";
import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { HttpPostFunction } from "@lookiero/sty-psp-http";
import { Image } from "../../../../domain/image/model/image";
import { ImagesGetFunction, ImagesSaveFunction } from "../../../../domain/image/model/images";
import { ViewImageById, ViewImageByIdResult, viewImageById } from "../../../../projection/image/viewImageById";
import { httpImagesUpload } from "./httpImagesUpload";

interface ToDomainFunction {
  (image: ViewImageByIdResult): Image | never;
}

const toDomain: ToDomainFunction = (image) => {
  invariant(image, "No image found!");

  return {
    id: image.id,
    quizId: image.quizId,
    aggregateId: image.id,
    domainEvents: [],
  };
};

interface HttpImagesGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpImagesGetFunction extends ImagesGetFunction<HttpImagesGetFunctionArgs> {}

const getImage: HttpImagesGetFunction =
  ({ queryBus }) =>
  async (aggregateId) =>
    toDomain(await queryBus<ViewImageById, ViewImageByIdResult>(viewImageById({ id: aggregateId })));

interface HttpImagesSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly multipartHttpPost: HttpPostFunction;
}

interface HttpImagesSaveFunction extends ImagesSaveFunction<HttpImagesSaveFunctionArgs> {}

const saveImage: HttpImagesSaveFunction =
  ({ multipartHttpPost }) =>
  async (aggregateRoot) =>
    await httpImagesUpload({ multipartHttpPost })(aggregateRoot);

export type { HttpImagesSaveFunction };
export { getImage, saveImage };
