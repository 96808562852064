import { Platform } from "react-native";
import { BuildBootstrapFunctionReturn } from "@lookiero/messaging-react";
import { fetchHttpPost, multipartFetchHttpPost } from "@lookiero/sty-psp-http";
import { VERSION } from "../../version";
import { getImage, saveImage } from "../domain/image/model/httpImages";
import { getQuiz, saveQuiz } from "../domain/quiz/model/httpQuizzes";
import { httpDefinitionByCountryAndSegmentView } from "../projection/definition/httpDefinitionByCountryAndSegmentView";
import { httpImageByIdView } from "../projection/image/httpImageByIdView";
import { httpQuizByIdView } from "../projection/quiz/httpQuizByIdView";
import { baseBootstrap } from "./baseBootstrap";
import { EventManager } from "./eventManager/eventManager";

type OS = typeof Platform.OS;
type Device = Exclude<OS, "macos" | "windows">;
const device = Platform.OS as Device;

interface BootstrapFunctionArgs {
  readonly apiUrl: () => string;
  readonly eventManager: EventManager;
}

interface BootstrapFunction {
  (args: BootstrapFunctionArgs): BuildBootstrapFunctionReturn;
}

const bootstrap: BootstrapFunction = ({ apiUrl, eventManager }) => {
  const httpPost = fetchHttpPost({ apiUrl, device, version: VERSION });
  const multipartHttpPost = multipartFetchHttpPost({ apiUrl, device, version: VERSION });

  return baseBootstrap({
    definitionByCountryAndSegmentView: httpDefinitionByCountryAndSegmentView({ httpPost }),
    imageByIdView: httpImageByIdView({ httpPost }),
    getImage,
    saveImage,
    imagesDependencies: [{ multipartHttpPost }],
    quizByIdView: httpQuizByIdView({ httpPost }),
    getQuiz,
    saveQuiz,
    quizzesDependencies: [{ httpPost }],
    emitQuizCompletedEventDependencies: { eventManager },
  });
};

export { bootstrap };
