import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { Country } from "@lookiero/sty-psp-locale";
import { QuestionProjection } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { Segment } from "@lookiero/sty-psp-segment";
import { viewDefinitionByCountryAndSegment } from "../../../../projection/definition/viewDefinitionByCountryAndSegment";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";

interface UseViewQuizDefinitionByCountryAndSegmentFunctionArgs {
  readonly country: Country;
  readonly segment: Segment;
  readonly version?: string;
  readonly experiment: string | undefined;
  readonly enabled: boolean;
}

interface UseViewQuizDefinitionByCountryAndSegmentFunction {
  (args: UseViewQuizDefinitionByCountryAndSegmentFunctionArgs): UseQueryFunctionResult<QuestionProjection[]>;
}

const useViewDefinitionByCountryAndSegment: UseViewQuizDefinitionByCountryAndSegmentFunction = ({
  country,
  segment,
  version,
  experiment,
  enabled,
}) =>
  useQuery({
    query: viewDefinitionByCountryAndSegment({ country, segment, version, experiment }),
    contextId: MESSAGING_CONTEXT_ID,
    options: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      enabled,
    },
  });

export { useViewDefinitionByCountryAndSegment };
