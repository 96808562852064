import { isQuizCompleted } from "../../../../domain/quiz/model/quizCompleted";
import { HttpQuizzesSaveFunction } from "./httpQuizzes";

interface HttpQuizzesCompleteFunction extends HttpQuizzesSaveFunction {}

const httpQuizzesComplete: HttpQuizzesCompleteFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents }) => {
    const quizCompleted = domainEvents.find(isQuizCompleted);

    if (!quizCompleted) {
      return;
    }

    await httpPost({
      endpoint: "/complete-quiz",
      body: {
        id: aggregateId,
      },
    });
  };

export { httpQuizzesComplete };
