import { useCallback } from "react";
import invariant from "tiny-invariant";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { Country } from "@lookiero/sty-psp-locale";
import { Logger } from "@lookiero/sty-psp-logging";
import { Segment } from "@lookiero/sty-psp-segment";
import { completeQuiz } from "../../../../domain/quiz/command/completeQuiz";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";

interface CompleteQuizFunctionArgs {
  readonly quizId: string | undefined;
}

interface CompleteQuizFunction {
  (args: CompleteQuizFunctionArgs): Promise<void> | undefined;
}

interface UseCompleteQuizFunctionArgs {
  readonly country: Country;
  readonly segment: Segment;
  readonly experiment?: string;
  readonly logger: Logger;
}

type UseCompleteQuizResult = [complete: CompleteQuizFunction, status: CommandStatus];

interface UseCompleteQuizFunction {
  (args: UseCompleteQuizFunctionArgs): UseCompleteQuizResult;
}

const useCompleteQuiz: UseCompleteQuizFunction = ({ country, segment, experiment, logger }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const complete: CompleteQuizFunction = useCallback(
    async ({ quizId }) => {
      invariant(quizId, "quizId must be defined in order to complete the quiz");

      try {
        await commandBus(completeQuiz({ aggregateId: quizId, country, segment, experiment }));
      } catch (error) {
        logger.captureException(error as Error);
      }
    },
    [commandBus, country, experiment, logger, segment],
  );

  return [complete, status];
};

export { useCompleteQuiz };
