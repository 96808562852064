import { command, Command } from "@lookiero/messaging";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";

const COMPLETE_QUIZ = "complete_quiz";

interface CompleteQuizPayload {
  readonly aggregateId: string;
  readonly country: Country;
  readonly segment: Segment;
  readonly experiment?: string;
}

interface CompleteQuiz extends Command<typeof COMPLETE_QUIZ>, CompleteQuizPayload {}

interface CompleteQuizFunction {
  (payload: CompleteQuizPayload): CompleteQuiz;
}

const completeQuiz: CompleteQuizFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: COMPLETE_QUIZ }),
  ...payload,
});

export { COMPLETE_QUIZ, completeQuiz };
export type { CompleteQuiz };
