import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const QUIZ_COMPLETED = "quiz_completed";

interface QuizCompletedPayload {
  readonly aggregateId: string;
}

interface QuizCompleted extends DomainEvent<typeof QUIZ_COMPLETED> {}

interface QuizCompletedFunction {
  (payload: QuizCompletedPayload): QuizCompleted;
}

const quizCompleted: QuizCompletedFunction = ({ aggregateId }) => domainEvent({ aggregateId, name: QUIZ_COMPLETED });

const isQuizCompleted = (event: DomainEvent<MessageName>): event is QuizCompleted => event.name === QUIZ_COMPLETED;

export type { QuizCompleted };
export { QUIZ_COMPLETED, isQuizCompleted, quizCompleted };
