import { HttpPostFunction } from "@lookiero/sty-psp-http";
import { QuizProjection } from "../../../projection/quiz/quiz";
import { QuizByIdView, ViewQuizByIdResult } from "../../../projection/quiz/viewQuizById";
import { GoneQuiz } from "./GoneQuiz";

interface HttpQuizByIdView extends QuizByIdView {}

interface HttpQuizByIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpQuizByIdViewFunction {
  (args: HttpQuizByIdViewFunctionArgs): HttpQuizByIdView;
}

const httpQuizByIdView: HttpQuizByIdViewFunction =
  ({ httpPost }) =>
  async ({ id, signal }) =>
    await httpPost<QuizProjection, ViewQuizByIdResult>({
      endpoint: "/view-quiz-by-id",
      body: {
        id,
      },
      signal,
      result: {
        error: (errorResponse) => (errorResponse.status === 410 ? GoneQuiz : null),
        success: (response) => response,
      },
    });

export { httpQuizByIdView };
