import { useFonts } from "expo-font";
import React, { FC } from "react";
import { Platform } from "react-native";
import { Navigate, Route, Routes } from "react-router-native";
import { Aurora } from "@lookiero/aurora";
import { EndpointFunction } from "@lookiero/i18n";
import { i18n } from "@lookiero/i18n-react";
import { fetchTranslations, translationExternalEndpoint } from "@lookiero/sty-psp-i18n";
import { Country, Locale } from "@lookiero/sty-psp-locale";
import { SentryEnvironment } from "@lookiero/sty-psp-logging";
import { Segment } from "@lookiero/sty-psp-segment";
import { KameleoonEnvironment } from "./infrastructure/ab-testing/kameleoonEnvironment";
// import { bootstrap as quizBootstrap } from "./infrastructure/delivery/bootstrap.mock";
import { bootstrap as quizBootstrap } from "./infrastructure/delivery/bootstrap";
import { EventManager } from "./infrastructure/delivery/eventManager/eventManager";
import { root } from "./infrastructure/ui/Root";
// import { singleQuestionRoot } from "./infrastructure/ui/SingleQuestionRoot";
import { DummyLayout } from "./infrastructure/ui/components/templates/dummyLayout/DummyLayout";
import { Router } from "./infrastructure/ui/routing/router/Router";
import { VERSION } from "./version";

const locale: Locale = Locale.es_ES;
const country: Country = Country.ES;
const segment: Segment = Segment.WOMEN;

const sentryConfig: SentryEnvironment = {
  publicKey: "f673d3dc166865c8e614a2aa29424297",
  release: VERSION,
  project: "4505907633782784",
  environment: `${Platform.OS}-EXPO`,
  enableIntegrations: false,
};

const apiUrl =
  Platform.OS !== "web" ? "https://web2.sp.dev.aws.lookiero.es/quiz/api" : __DEV__ ? "/local-to-dev" : "/quiz/api";

const externalTranslationsUrl =
  Platform.OS !== "web"
    ? "https://backend-for-user.dev.envs.lookiero.tech/api/v2/translations"
    : __DEV__
      ? "/local-to-external-i18n"
      : "/external-i18n";
const translations: EndpointFunction[] = [
  (locale) =>
    translationExternalEndpoint({
      translationsUrl: externalTranslationsUrl,
      projects: [["user-area-front"], ["quiz"]],
    })(locale),
];

const eventManager: EventManager = {
  emit: (event: string, payload: unknown) => {
    console.log("Emitting event ", event, payload);
  },
};

const kameleoonConfig: KameleoonEnvironment = {
  siteCode: "aplm4v3ckn",
  experiment: {
    id: "262141",
    variations: {
      v1: "1004339",
    },
  },
};

// const { Component: Messaging } = quizBootstrap({ eventManager });
const { Component: Messaging } = quizBootstrap({ apiUrl: () => apiUrl, eventManager });
const I18n = i18n({
  fetchTranslation: fetchTranslations({ translations }),
  contextId: "QuizI18n",
});
const Root = root({
  Messaging,
  I18n,
  development: false,
  sentry: () => sentryConfig,
  kameleoon: () => kameleoonConfig,
})({
  customerId: undefined,
});
// const Root = singleQuestionRoot({ Messaging, I18n, development: false, sentry: () => sentryConfig })({
//   customerId: undefined,
// });

const ExpoRoot: FC = () => {
  const [fontsLoaded] = useFonts({
    ["AreaInktrap-Semibold"]: require("@lookiero/aurora-fonts/AreaInktrap-Semibold.otf"),
    ["AreaNormal-Semibold"]: require("@lookiero/aurora-fonts/AreaNormal-Semibold.otf"),
    ["AreaNormal-Extrabold"]: require("@lookiero/aurora-fonts/AreaNormal-Extrabold.otf"),
    auroraicons: require("@lookiero/aurora-iconfont/dist/auroraicons.ttf"),
  });

  return fontsLoaded ? (
    <Aurora>
      <Router>
        <Routes>
          <Route
            path="/quiz/*"
            element={
              <Root
                apiUrl={() => apiUrl}
                basePath="/quiz"
                country={country}
                layout={DummyLayout}
                locale={locale}
                segment={segment}
                // onLoaded={() => void 0}
                // onSingleQuestionAnswered={() => void 0}
                // onSingleQuestionFilled={() => void 0}
              />
            }
          />

          <Route element={<Navigate to="/quiz" replace />} path="*" />
        </Routes>
      </Router>
    </Aurora>
  ) : null;
};

export { ExpoRoot };
