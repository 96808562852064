import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { QuizProjection } from "./quiz";

const VIEW_QUIZ_BY_ID = "view_quiz_by_id";

interface ViewQuizByIdPayload {
  readonly id: string;
}

interface ViewQuizById extends Query<typeof VIEW_QUIZ_BY_ID>, ViewQuizByIdPayload {}

interface ViewQuizByIdFunction {
  (payload: ViewQuizByIdPayload): ViewQuizById;
}

const viewQuizById: ViewQuizByIdFunction = (payload) => ({
  ...query({ name: VIEW_QUIZ_BY_ID }),
  ...payload,
});

type ViewQuizByIdResult = QuizProjection | null;

interface QuizByIdViewArgs extends CancelableQueryViewArgs {
  readonly id: string;
}

interface QuizByIdView {
  (args: QuizByIdViewArgs): Promise<ViewQuizByIdResult>;
}

interface ViewQuizByIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: QuizByIdView;
}

const viewQuizByIdHandler: QueryHandlerFunction<ViewQuizById, ViewQuizByIdResult, ViewQuizByIdHandlerFunctionArgs> =
  ({ view, signal }) =>
  async ({ id }) =>
    view({ id, signal });

export type { ViewQuizById, QuizByIdView, ViewQuizByIdResult };
export { VIEW_QUIZ_BY_ID, viewQuizById, viewQuizByIdHandler };
