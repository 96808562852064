import { useCallback } from "react";
import invariant from "tiny-invariant";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { Logger } from "@lookiero/sty-psp-logging";
import { NotificationLevel, useCreateToastNotification } from "@lookiero/sty-psp-notifications";
import { uploadImage } from "../../../../domain/image/command/uploadImage";
import { ImagePayload } from "../../../../domain/image/model/image";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { I18nMessages } from "../../../ui/i18n/i18n";

interface UploadImageFunctionArgs {
  readonly id: string;
  readonly image: ImagePayload;
  readonly quizId: string | undefined;
}
interface UploadImageFunction {
  (args: UploadImageFunctionArgs): Promise<void> | undefined;
}

interface UseUploadImageArgs {
  readonly logger: Logger;
}

type UseUploadImageResult = [upload: UploadImageFunction, status: CommandStatus];

interface UseUploadImage {
  (args: UseUploadImageArgs): UseUploadImageResult;
}

const useUploadImage: UseUploadImage = ({ logger }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });
  const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });

  const upload: UploadImageFunction = useCallback(
    async ({ id, image, quizId }) => {
      invariant(quizId, "quizId must be defined in order to upload image");

      try {
        await commandBus(uploadImage({ aggregateId: id, image, quizId }));
      } catch (error) {
        logger.captureException(error as Error);
        createNotification({
          level: NotificationLevel.ERROR,
          bodyI18nKey: I18nMessages.UPLOAD_IMAGE_TOAST_GENERIC_ERROR,
        });
      }
    },
    [commandBus, createNotification, logger],
  );

  return [upload, status];
};

export { useUploadImage };
