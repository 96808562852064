import { useEffect } from "react";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { isQuizCompleted } from "../../../../domain/quiz/model/quizCompleted";
import { isQuizFilled } from "../../../../domain/quiz/model/quizFilled";
import { QuizProjection } from "../../../../projection/quiz/quiz";
import { ViewQuizByIdResult, viewQuizById } from "../../../../projection/quiz/viewQuizById";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { useQuizId } from "../../../ui/components/organisms/question/behaviours/useQuizId";
import { GoneQuiz } from "../GoneQuiz";

interface UseViewQuizByIdFunctionArgs {
  readonly id: string | undefined;
}

interface UseViewQuizByIdFunction {
  (args: UseViewQuizByIdFunctionArgs): UseQueryFunctionResult<QuizProjection>;
}

const shouldInvalidate = (event: DomainEvent<MessageName>) => isQuizFilled(event) || isQuizCompleted(event);

const useViewQuizById: UseViewQuizByIdFunction = ({ id }) => {
  const [, createQuizId] = useQuizId();
  const [quiz, quizStatus] = useQuery<ViewQuizByIdResult>({
    query: viewQuizById({ id: id as string }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: shouldInvalidate,
    options: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(id),
    },
  });

  useEffect(() => {
    if (quiz === GoneQuiz) {
      createQuizId();
    }
  }, [createQuizId, quiz]);

  /**
   * Quiz may be null (error response)
   * But in order for the later integration to be easier
   * we are doing this type assertion.
   */
  return [quiz === GoneQuiz ? undefined : (quiz as QuizProjection), quizStatus];
};

export { useViewQuizById };
