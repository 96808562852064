import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { UploadImage } from "../command/uploadImage";
import { imageUploaded } from "./imageUploaded";

interface ImagePicker {
  readonly name: string;
  readonly type: string;
  readonly uri: string;
}

type ImagePayload = ImagePicker | Blob;

interface Image extends AggregateRoot {
  readonly quizId: string;
  readonly image?: ImagePayload;
}

const uploadImageHandler: CommandHandlerFunction<UploadImage, Image> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, quizId, image } = command;

    return {
      ...aggregateRoot,
      quizId,
      image,
      domainEvents: [imageUploaded({ aggregateId })],
    };
  };

export type { Image, ImagePayload };
export { uploadImageHandler };
