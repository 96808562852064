import { PortalProvider } from "@gorhom/portal";
import React, { FC, ReactNode } from "react";
import { StatusBar } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { theme } from "@lookiero/sty-psp-ui";

const { colorBgBase } = theme();

interface AppProps {
  readonly children: ReactNode;
}

const App: FC<AppProps> = ({ children }) => (
  <SafeAreaProvider>
    <PortalProvider>
      <StatusBar backgroundColor={colorBgBase} barStyle="dark-content" translucent />
      {children}
    </PortalProvider>
  </SafeAreaProvider>
);

export { App };
