import invariant from "tiny-invariant";
import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { HttpPostFunction } from "@lookiero/sty-psp-http";
import { Quiz } from "../../../../domain/quiz/model/quiz";
import { QuizzesGetFunction, QuizzesSaveFunction } from "../../../../domain/quiz/model/quizzes";
import { ViewQuizById, ViewQuizByIdResult, viewQuizById } from "../../../../projection/quiz/viewQuizById";
import { httpQuizzesComplete } from "./httpQuizzesComplete";
import { httpQuizzesFill } from "./httpQuizzesFill";

interface ToDomainFunction {
  (image: ViewQuizByIdResult): Quiz | never;
}

const toDomain: ToDomainFunction = (quiz) => {
  invariant(quiz, "No quiz found!");

  return {
    aggregateId: quiz.id,
    country: quiz.country,
    segment: quiz.segment,
    answers: quiz.answers,
    hostId: undefined,
    experiment: undefined,
    domainEvents: [],
  };
};

interface HttpQuizzesGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpQuizzesGetFunction extends QuizzesGetFunction<HttpQuizzesGetFunctionArgs> {}

const getQuiz: HttpQuizzesGetFunction =
  ({ queryBus }) =>
  async (aggregateId) =>
    toDomain(await queryBus<ViewQuizById, ViewQuizByIdResult>(viewQuizById({ id: aggregateId })));

interface HttpQuizzesSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpQuizzesSaveFunction extends QuizzesSaveFunction<HttpQuizzesSaveFunctionArgs> {}

const saveQuiz: HttpQuizzesSaveFunction =
  ({ httpPost }) =>
  async (aggregateRoot) => {
    await Promise.all([
      await httpQuizzesFill({ httpPost })(aggregateRoot),
      await httpQuizzesComplete({ httpPost })(aggregateRoot),
    ]);
  };

export type { HttpQuizzesSaveFunction };
export { getQuiz, saveQuiz };
