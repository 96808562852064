import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { QuizProjection } from "../../projection/quiz/quiz";
import { PROJECT, StartQuizTrackingEvent, TrackingEventName } from "./tracking";

interface TrackStartQuizFunctionArgs {
  readonly quiz: QuizProjection | null;
}

interface TrackStartQuizFunction {
  (args: TrackStartQuizFunctionArgs): void;
}

interface UseTrackStartQuizFunctionArgs {
  readonly country: Country;
  readonly segment: Segment;
}
interface UseTrackStartQuizFunction {
  (args: UseTrackStartQuizFunctionArgs): TrackStartQuizFunction;
}
const useTrackStartQuiz: UseTrackStartQuizFunction = ({ country, segment }) => {
  const emitUserEvent = useEmitUserEvent<StartQuizTrackingEvent>();

  const trackStartQuiz: TrackStartQuizFunction = useCallback(
    ({ quiz }) => {
      if (quiz) {
        return;
      }

      emitUserEvent({
        event: TrackingEventName.START_QUIZ,
        eventCategory: TrackingEventCategory.NAVIGATION,
        section: PROJECT,
        segment,
        store: country,
      });
    },
    [country, emitUserEvent, segment],
  );

  return trackStartQuiz;
};

export { useTrackStartQuiz };
