import {
  AggregateRoot,
  DomainEvent,
  MessageName,
  ProcessManagerFunction,
  ProcessManagerFunctionArgs,
  RepositoryGetFunction,
  RepositoryGetFunctionArgs,
  RepositorySaveFunction,
  RepositorySaveFunctionArgs,
} from "@lookiero/messaging";
import { BuildBootstrapFunctionReturn, bootstrap as messagingBootstrap } from "@lookiero/messaging-react";
import {
  bootstrapWithBuilder as bootstrapNotifications,
  inMemoryStorageNotifications,
} from "@lookiero/sty-psp-notifications";
import { bootstrapWithBuilder as bootstrapQuizStyleProfileCommonUi } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { bootstrapWithBuilder as bootstrapUiSettings, asyncStorageUiSettings } from "@lookiero/sty-psp-ui-settings";
import { UPLOAD_IMAGE } from "../../domain/image/command/uploadImage";
import { Image, uploadImageHandler } from "../../domain/image/model/image";
import { ImagesGetFunction, ImagesSaveFunction } from "../../domain/image/model/images";
import { COMPLETE_QUIZ } from "../../domain/quiz/command/completeQuiz";
import { FILL_QUIZ } from "../../domain/quiz/command/fillQuiz";
import {
  EmitQuizCompletedEventWhenQuizCompletedFunctionArgs,
  emitQuizCompletedEventWhenQuizCompleted,
} from "../../domain/quiz/event/emitQuizCompletedEventWhenQuizCompleted";
import { Quiz, completeQuizHandler, fillQuizHandler } from "../../domain/quiz/model/quiz";
import { QUIZ_COMPLETED } from "../../domain/quiz/model/quizCompleted";
import { QuizzesGetFunction, QuizzesSaveFunction } from "../../domain/quiz/model/quizzes";
import {
  DefinitionByCountryAndSegmentView,
  VIEW_DEFINITION_BY_COUNTRY_AND_SEGMENT,
  viewDefinitionByCountryAndSegmentHandler,
} from "../../projection/definition/viewDefinitionByCountryAndSegment";
import {
  VIEW_FIRST_QUESTION_FROM_DEFINITION_BY_COUNTRY_AND_SEGMENT,
  viewFirstQuestionFromDefinitionByCountryAndSegmentHandler,
} from "../../projection/definition/viewFirstQuestionFromDefinitionByCountryAndSegment";
import { ImageByIdView, VIEW_IMAGE_BY_ID, viewImageByIdHandler } from "../../projection/image/viewImageById";
import { QuizByIdView, VIEW_QUIZ_BY_ID, viewQuizByIdHandler } from "../../projection/quiz/viewQuizById";

const MESSAGING_CONTEXT_ID = "Quiz";

type NeverWhenEmptyRecord<K extends [Record<string, unknown>]> = K extends [Record<string, never>] ? [never?] : K;

type RepositoryDependencies<
  A extends AggregateRoot,
  GetFunctionArgs extends RepositoryGetFunctionArgs,
  SaveFunctionArgs extends RepositorySaveFunctionArgs,
> = Omit<
  Parameters<RepositoryGetFunction<A, GetFunctionArgs>>[0] & Parameters<RepositorySaveFunction<A, SaveFunctionArgs>>[0],
  keyof RepositoryGetFunctionArgs
>;

type ProcessManagerDependencies<Args extends ProcessManagerFunctionArgs> = Omit<
  Parameters<ProcessManagerFunction<DomainEvent<MessageName>, Args>>[0],
  keyof ProcessManagerFunctionArgs
>;

interface BaseBootstrapFunctionArgs<
  ImageGetFunctionArgs extends RepositoryGetFunctionArgs,
  ImageSaveFunctionArgs extends RepositorySaveFunctionArgs,
  QuizGetFunctionArgs extends RepositoryGetFunctionArgs,
  QuizSaveFunctionArgs extends RepositorySaveFunctionArgs,
> {
  readonly definitionByCountryAndSegmentView: DefinitionByCountryAndSegmentView;
  readonly imageByIdView: ImageByIdView;
  readonly getImage: ImagesGetFunction<ImageGetFunctionArgs>;
  readonly saveImage: ImagesSaveFunction<ImageSaveFunctionArgs>;
  readonly imagesDependencies: NeverWhenEmptyRecord<
    [RepositoryDependencies<Image, ImageGetFunctionArgs, ImageSaveFunctionArgs>]
  >;
  readonly quizByIdView: QuizByIdView;
  readonly getQuiz: QuizzesGetFunction<QuizGetFunctionArgs>;
  readonly saveQuiz: QuizzesSaveFunction<QuizSaveFunctionArgs>;
  readonly quizzesDependencies: NeverWhenEmptyRecord<
    [RepositoryDependencies<Quiz, QuizGetFunctionArgs, QuizSaveFunctionArgs>]
  >;
  readonly emitQuizCompletedEventDependencies: ProcessManagerDependencies<EmitQuizCompletedEventWhenQuizCompletedFunctionArgs>;
}

interface BaseBootstrapFunction {
  <
    ImageGetFunctionArgs extends RepositoryGetFunctionArgs,
    ImageSaveFunctionArgs extends RepositorySaveFunctionArgs,
    QuizGetFunctionArgs extends RepositoryGetFunctionArgs,
    QuizSaveFunctionArgs extends RepositorySaveFunctionArgs,
  >(
    args: BaseBootstrapFunctionArgs<
      ImageGetFunctionArgs,
      ImageSaveFunctionArgs,
      QuizGetFunctionArgs,
      QuizSaveFunctionArgs
    >,
  ): BuildBootstrapFunctionReturn;
}

const baseBootstrap: BaseBootstrapFunction = ({
  definitionByCountryAndSegmentView,
  imageByIdView,
  getImage,
  saveImage,
  imagesDependencies,
  quizByIdView,
  getQuiz,
  saveQuiz,
  quizzesDependencies,
  emitQuizCompletedEventDependencies,
}) => {
  let messaging = messagingBootstrap({ id: MESSAGING_CONTEXT_ID })
    .query(VIEW_DEFINITION_BY_COUNTRY_AND_SEGMENT, viewDefinitionByCountryAndSegmentHandler, {
      view: definitionByCountryAndSegmentView,
    })
    .query(
      VIEW_FIRST_QUESTION_FROM_DEFINITION_BY_COUNTRY_AND_SEGMENT,
      viewFirstQuestionFromDefinitionByCountryAndSegmentHandler,
    )
    .query(VIEW_IMAGE_BY_ID, viewImageByIdHandler, {
      view: imageByIdView,
    })
    .command(UPLOAD_IMAGE, uploadImageHandler)(getImage, saveImage, ...imagesDependencies)
    .query(VIEW_QUIZ_BY_ID, viewQuizByIdHandler, {
      view: quizByIdView,
    })
    .command(FILL_QUIZ, fillQuizHandler)(getQuiz, saveQuiz, ...quizzesDependencies)
    .command(COMPLETE_QUIZ, completeQuizHandler)(getQuiz, saveQuiz, ...quizzesDependencies)
    .processManager(QUIZ_COMPLETED, emitQuizCompletedEventWhenQuizCompleted, emitQuizCompletedEventDependencies);

  messaging = bootstrapNotifications({ messaging, storage: inMemoryStorageNotifications() });

  messaging = bootstrapUiSettings({ messaging, storage: asyncStorageUiSettings() });

  messaging = bootstrapQuizStyleProfileCommonUi({ messaging });

  return messaging.build();
};

export { MESSAGING_CONTEXT_ID, baseBootstrap };
