import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { Country } from "@lookiero/sty-psp-locale";
import {
  QuestionProjection,
  ValidationProjection,
  mapAnswersToImperialSystem,
  mapAnswersToInternationalSystem,
  questionById,
  viewValidation,
} from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { Segment } from "@lookiero/sty-psp-segment";
import { viewDefinitionByCountryAndSegment } from "../../../projection/definition/viewDefinitionByCountryAndSegment";
import { CompleteQuiz } from "../command/completeQuiz";
import { FillQuiz } from "../command/fillQuiz";
import { quizCompleted } from "./quizCompleted";
import { quizFilled } from "./quizFilled";

type Answers = Record<string, string[]>;

interface Quiz extends AggregateRoot {
  readonly country: Country;
  readonly segment: Segment;
  readonly answers: Answers;
  readonly hostId?: string;
  readonly experiment?: string;
}

const fillQuizHandler: CommandHandlerFunction<FillQuiz, Quiz> =
  ({ queryBus }) =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, country, segment, answers, hostId, experiment } = command;
    const questions: QuestionProjection[] = await queryBus(
      viewDefinitionByCountryAndSegment({ country, segment, experiment }),
    );
    const question = questionById({ questionId: hostId, questions });

    if (!question) {
      throw new Error("Can not fill the quiz. Question not found");
    }

    const validation: ValidationProjection = await queryBus(viewValidation({ answers, questions: [question] }));

    if (validation?.length > 0) {
      throw new Error("Can not fill the quiz. There are validation errors.");
    }

    return {
      ...aggregateRoot,
      country,
      segment,
      answers: mapAnswersToInternationalSystem({ answers, questions }),
      hostId,
      experiment,
      domainEvents: [quizFilled({ aggregateId })],
    };
  };

const completeQuizHandler: CommandHandlerFunction<CompleteQuiz, Quiz> =
  ({ queryBus }) =>
  async ({ aggregateRoot, command }) => {
    const { country, segment, experiment } = command;
    const questions: QuestionProjection[] = await queryBus(
      viewDefinitionByCountryAndSegment({ country, segment, experiment }),
    );
    const validation: ValidationProjection = await queryBus(
      viewValidation({
        answers: aggregateRoot.answers
          ? mapAnswersToImperialSystem({ answers: aggregateRoot.answers, questions })
          : aggregateRoot.answers,
        questions,
      }),
    );

    if (validation?.length > 0) {
      throw new Error("Can not complete the quiz. There are validation errors.");
      // TODO
      // throw a custom error containing the validation issues??
      // this way we could navigate to the first screen with validation issues.
    }

    return {
      ...aggregateRoot,
      domainEvents: [quizCompleted({ aggregateId: aggregateRoot.aggregateId })],
    };
  };

export { completeQuizHandler, fillQuizHandler };
export type { Answers, Quiz };
