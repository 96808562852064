import { AnswersProjection } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { BaseTrackingEvent, TrackingEventCategory } from "@lookiero/sty-psp-tracking";

const PROJECT = "quiz";

enum TrackingPage {
  NOT_FOUND = "not_found",
}

enum TrackingEventName {
  PAGEVIEW = "pageview",
  START_QUIZ = "quizStarted",
  FILL_QUIZ = "quizFilled",
  UPLOAD_PHOTO_UPLOAD_ICON_SELECTED = "uploadPhotoUploadIconSelected",
  UPLOAD_PHOTO_OPTIONS_MODAL_VIEW = "uploadPhotoOptionsModalView",
  UPLOAD_PHOTO_TAKE_PHOTO_OPTION_SELECTED = "uploadPhotoTakePhotoOptionSelected",
  UPLOAD_PHOTO_CHOOSE_LIBRARY_OPTION_SELECTED = "uploadPhotoChooseLibraryOptionSelected",
  UPLOAD_PHOTO_ERROR = "uploadPhotoError",
  IMAGE_UPLOADED = "imageUploaded",
  UPLOAD_PHOTO_OPTIONS_MODAL_CLOSE = "uploadPhotoOptionsModalClose",
  UPLOAD_PHOTO_CHOOSE_LIBRARY_OPTION_CANCELED = "uploadPhotoChooseLibraryOptionCanceled",
  UPLOAD_PHOTO_TAKE_PHOTO_OPTION_CANCELED = "uploadPhotoTakePhotoOptionCanceled",
  AB_TEST = "abtest",
  QUESTION_ERROR = "questionError",
}

interface QuizBaseTrackingEvent<
  EN extends TrackingEventName,
  EC extends TrackingEventCategory = TrackingEventCategory.NAVIGATION,
> extends BaseTrackingEvent<EN, EC> {
  readonly quizId?: string;
}

type PageViewTrackingEvent = QuizBaseTrackingEvent<TrackingEventName.PAGEVIEW>;

type StartQuizTrackingEvent = QuizBaseTrackingEvent<TrackingEventName.START_QUIZ>;

interface FillQuizTrackingEvent extends QuizBaseTrackingEvent<TrackingEventName.FILL_QUIZ> {
  readonly group: string;
  readonly question: string;
  readonly answers: AnswersProjection;
}

type UploadPhotoUploadIconSelectedTrackingEvent =
  QuizBaseTrackingEvent<TrackingEventName.UPLOAD_PHOTO_UPLOAD_ICON_SELECTED>;

type UploadPhotoOptionsModalViewTrackingEvent =
  QuizBaseTrackingEvent<TrackingEventName.UPLOAD_PHOTO_OPTIONS_MODAL_VIEW>;

type UploadPhotoTakePhotoOptionSelectedTrackingEvent =
  QuizBaseTrackingEvent<TrackingEventName.UPLOAD_PHOTO_TAKE_PHOTO_OPTION_SELECTED>;

type UploadPhotoChooseLibraryOptionSelectedTrackingEvent =
  QuizBaseTrackingEvent<TrackingEventName.UPLOAD_PHOTO_CHOOSE_LIBRARY_OPTION_SELECTED>;

type UploadPhotoErrorTrackingEvent = QuizBaseTrackingEvent<TrackingEventName.UPLOAD_PHOTO_ERROR>;

type ImageUploadedTrackingEvent = QuizBaseTrackingEvent<TrackingEventName.IMAGE_UPLOADED>;

type UploadPhotoOptionsModalCloseTrackingEvent =
  QuizBaseTrackingEvent<TrackingEventName.UPLOAD_PHOTO_OPTIONS_MODAL_CLOSE>;

type UploadPhotoChooseLibraryOptionCanceledTrackingEvent =
  QuizBaseTrackingEvent<TrackingEventName.UPLOAD_PHOTO_CHOOSE_LIBRARY_OPTION_CANCELED>;

type UploadPhotoTakePhotoOptionCanceledTrackingEvent =
  QuizBaseTrackingEvent<TrackingEventName.UPLOAD_PHOTO_TAKE_PHOTO_OPTION_CANCELED>;

interface AbTestTrackingEvent extends QuizBaseTrackingEvent<TrackingEventName.AB_TEST> {
  readonly experiment: string;
  readonly variation: string;
}

interface QuestionErrorTrackingEvent extends QuizBaseTrackingEvent<TrackingEventName.QUESTION_ERROR> {
  readonly group: string;
  readonly errorType: "format" | "mandatory";
}

export { PROJECT, TrackingEventName, TrackingPage };
export type {
  FillQuizTrackingEvent,
  PageViewTrackingEvent,
  StartQuizTrackingEvent,
  UploadPhotoUploadIconSelectedTrackingEvent,
  UploadPhotoOptionsModalViewTrackingEvent,
  UploadPhotoTakePhotoOptionSelectedTrackingEvent,
  UploadPhotoErrorTrackingEvent,
  UploadPhotoChooseLibraryOptionSelectedTrackingEvent,
  ImageUploadedTrackingEvent,
  UploadPhotoOptionsModalCloseTrackingEvent,
  UploadPhotoChooseLibraryOptionCanceledTrackingEvent,
  UploadPhotoTakePhotoOptionCanceledTrackingEvent,
  AbTestTrackingEvent,
  QuestionErrorTrackingEvent,
};
