import { HttpPostFunction } from "@lookiero/sty-psp-http";
import { Layout, QuestionProjection, QuestionType } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import {
  PresentationGridLayout,
  LayoutColumns,
  PresentationLayout,
  Metadata,
  questionHasChildren,
} from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import {
  DefinitionByCountryAndSegmentView,
  ViewDefinitionByCountryAndSegmentResult,
} from "../../../projection/definition/viewDefinitionByCountryAndSegment";

interface PresentationGridLayoutDto extends Omit<PresentationGridLayout, "layoutColumns"> {
  readonly layoutColumns: LayoutColumns | string;
}

type PresentationLayoutDto = Exclude<PresentationLayout, PresentationGridLayout> | PresentationGridLayoutDto;

const metadataHasPresentationGridLayout = (
  layoutMetadata: PresentationLayoutDto,
): layoutMetadata is PresentationGridLayoutDto => layoutMetadata.layout === Layout.GRID;

const toProjection = <QT extends QuestionType>(question: QuestionProjection<QT>): QuestionProjection<QT> => {
  const metadata: Metadata<QT> =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    question.metadata || {};

  const presentationLayoutMetadata: PresentationLayoutDto =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    question.metadata || {};

  return {
    ...question,
    metadata: {
      ...metadata,
      ...(metadataHasPresentationGridLayout(presentationLayoutMetadata)
        ? {
            layoutColumns:
              typeof presentationLayoutMetadata.layoutColumns === "string"
                ? (JSON.parse(presentationLayoutMetadata.layoutColumns) as LayoutColumns)
                : presentationLayoutMetadata.layoutColumns,
          }
        : {}),
    },
    children: questionHasChildren(question) ? question.children.map(toProjection) : [],
  };
};

interface HttpDefinitionByCountryAndSegmentView extends DefinitionByCountryAndSegmentView {}

interface HttpDefinitionByCountryAndSegmentViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpDefinitionByCountryAndSegmentViewFunction {
  (args: HttpDefinitionByCountryAndSegmentViewFunctionArgs): HttpDefinitionByCountryAndSegmentView;
}

const httpDefinitionByCountryAndSegmentView: HttpDefinitionByCountryAndSegmentViewFunction =
  ({ httpPost }) =>
  async ({ country, segment, version, experiment, signal }) =>
    await httpPost<QuestionProjection[], ViewDefinitionByCountryAndSegmentResult>({
      endpoint: "/view-quiz-definition-by-country-and-segment",
      body: { country, segment, version, experiment },
      signal,
      result: {
        error: null,
        success: (response) => response.map(toProjection),
      },
    });

export { httpDefinitionByCountryAndSegmentView, toProjection };
